<template>
  <v-container>
    <v-row>
      <!-- Left Side: Input Sections -->
      <v-col cols="8">
        <!-- Partner's Name -->
        <v-text-field
          v-model="partnerName"
          label="Partner's Name"
          required
        ></v-text-field>

        <!-- Story of How We Met -->
        <v-textarea
          v-model="story"
          label="Story of how we met"
          rows="4"
          required
        ></v-textarea>

        <!-- Shareable Moments -->
        <div>
          <h3>Shareable Moments</h3>
          <div v-for="(moment, index) in moments" :key="index" class="moment-input">
            <v-text-field
              v-model="moments[index]"
              label="Moment to share..."
              required
            ></v-text-field>
            <v-btn icon @click="addMoment" v-if="index === moments.length - 1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn icon @click="removeMoment(index)" v-if="moments.length > 1">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Vows -->
        <div>
          <h3>Your Vows</h3>
          <div v-for="(vow, index) in vows" :key="index" class="vow-input">
            <v-text-field
              v-model="vows[index]"
              label="I vow to..."
              required
            ></v-text-field>
            <v-btn icon @click="addVow" v-if="index === vows.length - 1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn icon @click="removeVow(index)" v-if="vows.length > 1">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Themes -->
        <div>
          <h3>Select Themes</h3>
          <v-checkbox
            v-for="theme in availableThemes"
            :key="theme"
            :label="theme"
            :value="theme"
            v-model="themes"
            hide-details
          ></v-checkbox>
        </div>

        <!-- Length of Vows Dropdown -->
        <div>
          <h3>Vow Length</h3>
          <v-select
            v-model="vowLength"
            :items="['Short', 'Medium', 'Long']"
            label="Select Length"
            required
          ></v-select>
        </div>

        <!-- Generate Vow Button -->
        <v-btn color="primary" @click="generateVow">Generate Vow</v-btn>
      </v-col>

      <!-- Right Side: Display Generated Vow with Paragraph Breaks -->
      <v-col cols="4">
        <div v-if="generatedVow" class="generated-vow">
          <h3>Your Generated Vow</h3>
          <v-card>
            <!-- Loop through each paragraph and display as <p> -->
            <v-card-text>
              <p v-for="(paragraph, index) in splitIntoParagraphs(generatedVow)" :key="index">
                {{ paragraph }}
              </p>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      partnerName: '',
      story: '',
      moments: [''],
      vows: [''],
      themes: [],
      vowLength: '', // Added vow length
      availableThemes: ['Serious', 'Funny', 'Beautiful', 'Endearing', 'Spiritual', 'Christian', 'Jewish', 'Astrology'],
      generatedVow: ''
    }
  },
  methods: {
    addMoment() {
      this.moments.push('');
    },
    removeMoment(index) {
      this.moments.splice(index, 1);
    },
    addVow() {
      this.vows.push('');
    },
    removeVow(index) {
      this.vows.splice(index, 1);
    },
    generateVow() {
      // Translate length to GPT timing
      const vowLengthMap = {
        Short: 'no more than 200 words',
        Medium: 'no more than 300 words',
        Long: 'no more than 400 words'
      };

      this.$axios.post('/generate_vow', {
        partnerName: this.partnerName,
        story: this.story,
        moments: this.moments,
        vows: this.vows,
        themes: this.themes,
        length: vowLengthMap[this.vowLength] // Pass length to the backend
      })
      .then(response => {
        this.generatedVow = response.data.generated_vow;
      })
      .catch(error => {
        console.error(error);
        alert('An error occurred while generating the vow.');
      });
    },
    splitIntoParagraphs(vow) {
      // Split vow by double newlines (paragraph breaks)
      return vow.split(/\n\s*\n/);
    }
  }
}
</script>

<!-- Apply the "Playpen Sans" font for the generated vow -->
<style scoped>
.generated-vow {
  font-family: 'Playpen Sans', cursive;
  font-weight: 400;  /* Adjust weight as needed */
  font-optical-sizing: auto;
  font-size: 18px;
  margin-top: 20px;
}

.generated-vow p {
  margin-bottom: 15px;
  font-size: 18px;
}

.vow-title {
  font-family: 'Playpen Sans', cursive;
  font-weight: 600;  /* Optionally adjust weight for the title */
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
}
</style>

