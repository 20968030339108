<!-- src/App.vue -->
<template>
  <v-app>
    <SiteNavbar />
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SiteNavbar from './components/SiteNavbar.vue'

export default {
  components: {
    SiteNavbar
  }
}
</script>
