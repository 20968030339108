<!-- src/components/SiteNavbar.vue -->
<template>
  <v-app-bar app color="primary" dark>
    <v-toolbar-title>VowPal</v-toolbar-title>
    <v-spacer></v-spacer>
    <div v-if="!isLoggedIn">
      <v-btn text @click="$router.push('/login')">Login</v-btn>
      <v-btn text @click="$router.push('/register')">Register</v-btn>
    </div>
    <div v-else>
      <v-btn text @click="logout">Logout</v-btn>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: false,
    }
  },
  created() {
    // Check if the user is logged in
    this.isLoggedIn = !!localStorage.getItem('user');
  },
  methods: {
    logout() {
      this.$axios.post('/logout')
        .then(() => {
          localStorage.removeItem('user');
          this.isLoggedIn = false;
          this.$router.push('/login');
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
}
</script>
